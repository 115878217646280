import React, { Fragment, useContext, useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Modal,
    Row,
} from "react-bootstrap";
import { PlusLg, Trash } from "react-bootstrap-icons";

import UserContext from "../../context/UserContext";
import {
    APIEvent,
    getEvents,
    deleteEvent,
    createEvent,
} from "../../utils/api/api";

const Events = () => {
    const [events, setEvents] = useState<Array<APIEvent>>([]);
    const [showAddModal, setShowAddModal] = useState(false);

    const { user } = useContext(UserContext);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const res = await getEvents();
        if (res.data) {
            setEvents(res.data);
        }
    };

    const delEvent = async (id: string) => {
        const res = await deleteEvent(id);
        if (res.data) {
            setEvents((events) => events.filter((event) => event.id !== id));
        }
    };

    const onCloseModal = () => setShowAddModal(false);

    const onSubmitEvent = async (e: any) => {
        e.preventDefault();
        const { title, content, link, date } = e.target.elements;
        const res = await createEvent(
            title.value,
            content.value,
            date.value,
            link.value || undefined
        );

        setShowAddModal(false);

        if (res.data) {
            setEvents([]);
            await fetchData();
        }
    };

    const formattedDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        return `${year}-${("0" + month).slice(-2)}-${("0" + day).slice(-2)}`;
    };

    return (
        <Container className="h-100" style={{ overflowY: "auto" }}>
            {user.admin && (
                <Button variant="primary" onClick={() => setShowAddModal(true)}>
                    <PlusLg />
                </Button>
            )}
            {events.map((event) => (
                <Fragment key={event.id}>
                    <Row className="mb-3 position-relative">
                        <Col>
                            <Card className="text-center">
                                <Card.Body>
                                    <Card.Title>{event.title}</Card.Title>
                                    <Card.Text>{event.content}</Card.Text>
                                    {event.link && (
                                        <a href={event.link}>
                                            <Button variant="primary">
                                                Plus d'infos
                                            </Button>
                                        </a>
                                    )}
                                </Card.Body>
                                <Card.Footer className="text-muted">
                                    {event.date}
                                </Card.Footer>
                            </Card>
                        </Col>
                        {user.admin && (
                            <div
                                className="w-auto position-absolute"
                                style={{ right: "0" }}
                            >
                                <Button
                                    variant="danger"
                                    title="Supprimer"
                                    onClick={() => delEvent(event.id)}
                                >
                                    <Trash />
                                </Button>
                            </div>
                        )}
                    </Row>
                </Fragment>
            ))}
            {user.admin && (
                <Modal show={showAddModal} onHide={onCloseModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Ajouter un Event</Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                        as={Form}
                        id="addEventForm"
                        onSubmit={onSubmitEvent}
                    >
                        <Form.Group className="mb-3" controlId="title">
                            <Form.Label>Titre*</Form.Label>
                            <Form.Control placeholder="Titre" required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="content">
                            <Form.Label>Contenu*</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Contenu"
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="date">
                            <Form.Label>Date*</Form.Label>
                            <Form.Control
                                type="date"
                                defaultValue={formattedDate()}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="link">
                            <Form.Label>Lien</Form.Label>
                            <Form.Control placeholder="https://www.stonebutchblues.juliencaillaud.com" />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onCloseModal}>
                            Annuler
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            form="addEventForm"
                        >
                            Ajouter
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Container>
    );
};

export default Events;
