import React from "react";

export type UserType = {
    username: string;
    password: string;
    admin: boolean;
};

const UserContext = React.createContext<{
    user: UserType;
    setUser: (user: UserType) => void;
}>({
    user: { username: "", password: "", admin: false },
    setUser(user: UserType) {},
});
export const UserProvider = UserContext.Provider;
export default UserContext;
