import React, { useEffect, useRef, useState } from "react";

import "./Landing.scss";

type LandingProps = {
    onAnimationEnd: () => void;
};

const Landing: React.FC<LandingProps> = (props) => {
    const [isLeft, setIsLeft] = useState(true);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // const animationTimeout = setTimeout(() => {
        //     setIsLeft(false);
        //     props.onAnimationEnd();
        // }, 1500);

        // return () => clearTimeout(animationTimeout);
        setIsLeft(false);
        props.onAnimationEnd();
    }, []);

    return (
        <div className="Landing page" ref={ref}>
            <div className="background"></div>
            <div className="circle" style={{ left: isLeft ? 0 : "200px" }}>
                <h1>
                    <div>STONE</div>
                    <div>BUTCH</div>
                    <div>BLUES</div>
                </h1>
            </div>
        </div>
    );
};

export default Landing;
