import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, FormSelect, Row } from "react-bootstrap";

type AudioReaderProps = {
    tracks: { title?: string; src: string }[];
};

const AudioReader: React.FC<AudioReaderProps> = (props) => {
    const { t } = useTranslation();

    const [index, setIndex] = useState(0);

    const track = props.tracks[index];

    return (
        <Container fluid>
            {props.tracks.length > 1 && (
                <Row className="my-2 justify-content-center">
                    <Col xs="auto">
                        <Button
                            variant="primary"
                            onClick={() =>
                                setIndex((index) => Math.max(index - 1, 0))
                            }
                            disabled={index < 1}
                        >
                            &lt;
                        </Button>
                    </Col>
                    <Col xs="auto">
                        <FormSelect
                            className="d-block mx-auto"
                            onChange={(e) => setIndex(Number(e.target.value))}
                            value={index.toString()}
                        >
                            {props.tracks.map((track, index) => (
                                <option key={index} value={index}>
                                    {track.title || track.src}
                                </option>
                            ))}
                        </FormSelect>
                    </Col>
                    <Col xs="auto">
                        <Button
                            variant="primary"
                            onClick={() =>
                                setIndex((index) =>
                                    Math.min(index + 1, props.tracks.length)
                                )
                            }
                            disabled={index > props.tracks.length - 2}
                        >
                            &gt;
                        </Button>
                    </Col>
                </Row>
            )}
            <Row className="justify-content-center">
                <Col xs="auto">
                    {track && (
                        <audio
                            controls
                            src={track.src}
                            style={{ height: 35, width: 350 }}
                        >
                            <a href={track.src}>{t("audio.download")}</a>
                        </audio>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default AudioReader;
