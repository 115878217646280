import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    useMemo,
} from "react";
import { useTranslation } from "react-i18next";

import Scroller from "./layout/scroller/Scroller";
import Header from "./layout/header/Header";
import Navbar from "./layout/navbar/Navbar";
import Socials from "./layout/socials/Socials";

import Landing from "./layout/landing/Landing";
import Page from "./layout/page/Page";

import Typescript from "./container/typescript/Typescript";
import Authors from "./container/authors/Authors";
import Gallery from "./container/gallery/Gallery";
import Cambourakis from "./container/cambourakis/Cambourakis";
//import Team from "./container/cambourakis/team/Team";
import Testimonials from "./container/testimonials/Testimonials";
import Events from "./container/events/Events";
import Contact from "./container/contact/Contact";

import { UserProvider, UserType } from "./context/UserContext";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
    const { t } = useTranslation();

    const translations = useMemo(
        () => ({
            home: t("navbar.home"),
            authors: t("navbar.authors"),
            cambourakis: t("navbar.cambourakis_ed"),
            gallery: t("navbar.gallery"),
            typescript: t("navbar.typescript"),
            testimonials: t("navbar.testimonials"),
            events: t("navbar.events"),
            other: t("navbar.others"),
            contact: t("navbar.contact"),
        }),
        [t]
    );

    const [user, setUser] = useState<UserType>({
        username: "",
        password: "",
        admin: false,
    });

    const [animationEnded, setAnimationEnded] = useState(false);
    const [showText, setShowText] = useState(false);
    const [subtitle, setSubtitle] = useState("");
    const [color, setColor] = useState("");

    const headerRef = useRef(null);

    const onAnimationEnd = useCallback(() => {
        setAnimationEnded(true);
        setShowText(true);
    }, []);

    useEffect(() => {
        if (!animationEnded) return;

        const observer = new IntersectionObserver(
            (entries) => {
                setShowText(!entries[0].isIntersecting);
            },
            { threshold: 0.1 }
        );

        if (headerRef.current) {
            observer.observe(headerRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [animationEnded]);

    const onObserve = (color: string) => (title: string) => {
        setColor(color);
        setSubtitle(title);
    };

    return (
        <UserProvider
            value={{
                user,
                setUser: (user) => setUser(user),
            }}
        >
            <Scroller>
                <Landing onAnimationEnd={onAnimationEnd} />
                <Header
                    ref={headerRef}
                    show={animationEnded}
                    subtitle={subtitle}
                    color={color}
                />
                {animationEnded && (
                    <>
                        <Page
                            id="home"
                            contentIndex={12}
                            onObserve={onObserve("--color1")}
                            title={translations.home}
                        />
                        <Page
                            id="authors"
                            onObserve={onObserve("--color2")}
                            title={translations.authors}
                        >
                            <Authors contentIndexes={[4, 6, 8]} />
                        </Page>
                        <Page
                            id="cambourakis"
                            onObserve={onObserve("--color3")}
                            title={translations.cambourakis}
                        >
                            <Cambourakis />
                        </Page>
                        <Page
                            id="gallery"
                            onObserve={onObserve("--color4")}
                            title={translations.gallery}
                        >
                            <Gallery />
                        </Page>
                        <Page
                            id="typescript"
                            onObserve={onObserve("--color5")}
                            title={translations.typescript}
                        >
                            <Typescript />
                        </Page>
                        <Page
                            id="testimonials"
                            onObserve={onObserve("--color6")}
                            title={translations.testimonials}
                        >
                            <Testimonials />
                        </Page>
                        <Page
                            id="events"
                            onObserve={onObserve("--color7")}
                            title={translations.events}
                        >
                            <Events />
                        </Page>
                        <Page
                            id="other"
                            contentIndex={14}
                            onObserve={onObserve("--color8")}
                            title={translations.other}
                        />
                        <Page
                            id="contact"
                            onObserve={onObserve("--color9")}
                            title={translations.contact}
                        >
                            <Contact />
                        </Page>
                    </>
                )}

                <Navbar showText={showText} translations={translations} />
                <Socials />
                <ToastContainer position="bottom-center" autoClose={2000} />
            </Scroller>
            {/* <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="testimonials" element={<Testimonials />} />
                    <Route path="typescript" element={<Typescript />} />
                    <Route path="authors/:author" element={<Authors />} />
                    <Route path="gallery" element={<Gallery />} />
                    <Route path="cambourakis" element={<Cambourakis />}>
                        <Route path="house" element={<Page titleIndex={1} />} />
                        <Route
                            path="sorcieres"
                            element={<Page titleIndex={9} />}
                        />
                        <Route path="team" element={<Team />} />
                        <Route path="contact" element={<Contact />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes> */}
        </UserProvider>
    );
}

export default App;
