import React, { forwardRef } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AudioReader from "../../common/audioReader/AudioReader";
import Login from "../../common/login/Login";
import TranslationSelect from "../../common/translationSelect/TranslationSelect";

import "./Header.scss";

type HeaderProps = {
    show: boolean;
    subtitle: string;
    color: string;
};

const Header = forwardRef<HTMLDivElement, HeaderProps>((props, ref) => {
    const { t } = useTranslation();

    return (
        <Container
            fluid
            className="header"
            ref={ref}
            style={{ height: !props.show ? 0 : undefined }}
        >
            {props.show && (
                <Row className="align-items-center">
                    <Col xs={4}>
                        <AudioReader
                            tracks={[
                                {
                                    title:
                                        t("audio.chapter", { num: 1 }) ||
                                        undefined,
                                    src: "assets/podcast/intro.mp3",
                                },
                                {
                                    title:
                                        t("audio.chapter", { num: 2 }) ||
                                        undefined,
                                    src: "assets/podcast/chapter1.mp3",
                                },
                            ]}
                        />
                    </Col>
                    <Col
                        xs={4}
                        className="d-flex align-items-center justify-content-center"
                    >
                        <div
                            className="circle"
                            style={{ backgroundColor: `var(${props.color})` }}
                        ></div>
                        <div className="title">
                            <h1>STONE BUTCH BLUES</h1>
                            <h2>{props.subtitle}</h2>
                        </div>
                    </Col>
                    <Col xs={1}>
                        <a href="https://www.cambourakis.com/" rel="noreferrer">
                            <Image
                                src="/assets/img/logo.png"
                                fluid
                                className="d-block mx-auto"
                                style={{ height: 100 }}
                            />
                        </a>
                    </Col>
                    <Col xs={1}>
                        <TranslationSelect languages={["fr", "en"]} />
                    </Col>
                    <Col xs={2}>
                        <Login />
                    </Col>
                </Row>
            )}
        </Container>
    );
});

export default Header;
