import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import HTMLFlipBook from "react-pageflip";

import {
    pdfjs,
    Document,
    Page as ReactPdfPage,
} from "react-pdf/dist/esm/entry.webpack5";

import "./Flipbook.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const Page = React.forwardRef<
    HTMLDivElement,
    { pageNumber: number; width: number }
>(({ pageNumber, width }, ref) => {
    return (
        <div ref={ref}>
            <ReactPdfPage
                pageNumber={pageNumber}
                width={width}
                renderAnnotationLayer={false}
                renderTextLayer={false}
            />
        </div>
    );
});

const PDF = React.memo<{ width: number; height: number; chapter: string }>(
    ({ width, height, chapter }) => {
        const [numPages, setNumPages] = useState(0);
        return (
            <Document
                file={`./assets/pdf/${chapter}`}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
                {/* @ts-expect-error */}
                <HTMLFlipBook width={width} height={height} flippingTime={500}>
                    {numPages > 0 &&
                        Array.from({ length: numPages }, (v, index) => (
                            <Page
                                pageNumber={index + 1}
                                width={width}
                                key={index}
                            />
                        ))}
                </HTMLFlipBook>
            </Document>
        );
    },
    (oldProps, newProps) => oldProps.chapter === newProps.chapter
);

type FlipbookProps = {
    show: boolean;
    onHide: () => void;
};

export const Flipbook: React.FC<FlipbookProps> = (props) => {
    const { show, onHide } = props;

    const { t } = useTranslation();
    const [chapter, setChapter] = useState(0);

    const chapters = useMemo(() => ["ssb1.pdf", "ssb2.pdf", "ssb3.pdf"], []);

    useEffect(() => {
        if (show) {
            setChapter(0);
        }
    }, [show]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName="flipbook-modal"
            centered
        >
            <Modal.Body>
                <Container>
                    <Row className="mb-1">
                        <Col xs={{ span: 3, offset: 1 }}>
                            <Button
                                className="d-block mx-auto"
                                onClick={() =>
                                    setChapter((chapter) =>
                                        Math.max(chapter - 1, 1)
                                    )
                                }
                                disabled={chapter < 2}
                            >
                                &lt; {t("flipbook.previous")}
                            </Button>
                        </Col>
                        <Col xs={4}>
                            <Form.Group
                                as={Row}
                                controlId="chapterSelect"
                                className="justify-content-center"
                            >
                                <Form.Label column xs="auto">
                                    {t("flipbook.chapter")}
                                </Form.Label>
                                <Col xs="auto">
                                    <Form.Select
                                        value={chapter}
                                        onChange={(e) =>
                                            setChapter(Number(e.target.value))
                                        }
                                    >
                                        <option disabled>0</option>
                                        {chapters.map((chapter, index) => (
                                            <option key={index}>
                                                {index + 1}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col xs={3}>
                            <Button
                                className="d-block mx-auto"
                                onClick={() =>
                                    setChapter((chapter) =>
                                        Math.min(chapter + 1, chapters.length)
                                    )
                                }
                                disabled={chapter >= chapters.length}
                            >
                                {t("flipbook.next")} &gt;
                            </Button>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col>
                            {chapter > 0 && (
                                <PDF
                                    width={560}
                                    height={792}
                                    chapter={chapters[chapter - 1]}
                                />
                            )}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
};
