import React from "react";

import "./Scroller.scss";

type ScrollerProps = {
    children: React.ReactNode;
};

const Scroller: React.FC<ScrollerProps> = ({ children }) => {
    return <div className="scroller">{children}</div>;
};

export default Scroller;
