import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { PencilFill, XLg, Save, Eye, EyeSlash } from "react-bootstrap-icons";

type EditHandlerProps = {
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    onSave: () => void;
    previewMode?: boolean;
    togglePreview?: () => void;
};

const EditHandler: React.FC<EditHandlerProps> = (props) => {
    const { editMode, setEditMode, onSave, previewMode, togglePreview } = props;

    return (
        <ButtonGroup aria-label="Basic example">
            {editMode ? (
                <>
                    <Button
                        variant="danger"
                        onClick={() => setEditMode(false)}
                        title="Annuler"
                    >
                        <XLg />
                    </Button>
                    {togglePreview !== undefined && (
                        <Button
                            variant="primary"
                            onClick={togglePreview}
                            title="Preview"
                        >
                            {previewMode ? <EyeSlash /> : <Eye />}
                        </Button>
                    )}
                    <Button
                        variant="success"
                        onClick={onSave}
                        title="Sauvegarder"
                    >
                        <Save />
                    </Button>
                </>
            ) : (
                <Button
                    variant="primary"
                    onClick={() => setEditMode(true)}
                    title="Editer"
                >
                    <PencilFill />
                </Button>
            )}
        </ButtonGroup>
    );
};

export default EditHandler;
