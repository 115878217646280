import React from "react";

import { Nav, Navbar } from "react-bootstrap";

import "./Navbar.scss";

type CustomNavbarProps = {
    showText?: boolean;
    translations: {
        home: string;
        authors: string;
        cambourakis: string;
        gallery: string;
        typescript: string;
        testimonials: string;
        events: string;
        other: string;
        contact: string;
    };
};

const CustomNavbar: React.FC<CustomNavbarProps> = (props) => {
    const { showText, translations } = props;
    return (
        <aside className={`Navbar${showText ? " showText" : ""}`}>
            <Navbar>
                <Nav className="flex-column">
                    <Nav.Link
                        href="#home"
                        title={translations.home}
                        target="_self"
                    >
                        <div
                            className="circle"
                            style={{ backgroundColor: "var(--color1)" }}
                        ></div>
                        <div className="hiddenText">{translations.home}</div>
                    </Nav.Link>
                    <Nav.Link
                        href="#authors"
                        title={translations.authors}
                        target="_self"
                    >
                        <div
                            className="circle"
                            style={{ backgroundColor: "var(--color2)" }}
                        ></div>
                        <div className="hiddenText">{translations.authors}</div>
                    </Nav.Link>
                    <Nav.Link
                        href="#cambourakis"
                        title={translations.cambourakis}
                        target="_self"
                    >
                        <div
                            className="circle"
                            style={{ backgroundColor: "var(--color3)" }}
                        ></div>
                        <div className="hiddenText">
                            {translations.cambourakis}
                        </div>
                    </Nav.Link>
                    <Nav.Link
                        href="#gallery"
                        title={translations.gallery}
                        target="_self"
                    >
                        <div
                            className="circle"
                            style={{ backgroundColor: "var(--color4)" }}
                        ></div>
                        <div className="hiddenText">{translations.gallery}</div>
                    </Nav.Link>
                    <Nav.Link
                        href="#typescript"
                        title={translations.typescript}
                        target="_self"
                    >
                        <div
                            className="circle"
                            style={{ backgroundColor: "var(--color5)" }}
                        ></div>
                        <div className="hiddenText important">
                            {translations.typescript}
                        </div>
                    </Nav.Link>
                    <Nav.Link
                        href="#testimonials"
                        title={translations.testimonials}
                        target="_self"
                    >
                        <div
                            className="circle"
                            style={{ backgroundColor: "var(--color6)" }}
                        ></div>
                        <div className="hiddenText">
                            {translations.testimonials}
                        </div>
                    </Nav.Link>
                    <Nav.Link
                        href="#events"
                        title={translations.events}
                        target="_self"
                    >
                        <div
                            className="circle"
                            style={{ backgroundColor: "var(--color7)" }}
                        ></div>
                        <div className="hiddenText">{translations.events}</div>
                    </Nav.Link>
                    <Nav.Link
                        href="#other"
                        title={translations.other}
                        target="_self"
                    >
                        <div
                            className="circle"
                            style={{ backgroundColor: "var(--color8)" }}
                        ></div>
                        <div className="hiddenText">{translations.other}</div>
                    </Nav.Link>
                    <Nav.Link
                        href="#contact"
                        title={translations.contact}
                        target="_self"
                    >
                        <div
                            className="circle"
                            style={{ backgroundColor: "var(--color9)" }}
                        ></div>
                        <div className="hiddenText">{translations.contact}</div>
                    </Nav.Link>
                </Nav>
            </Navbar>
        </aside>
    );
};

export default CustomNavbar;
