import React, { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import projects from "../../__assets__/json/projects.json";

import "./Gallery.scss";

const Gallery = () => {
    const { t } = useTranslation();

    const images = useMemo(
        () =>
            projects.data.reduce((images, data) => {
                if (!data.adult_content && data.cover.thumb_url) {
                    images.push({
                        title: data.title,
                        src: data.cover.thumb_url,
                        permalink: data.permalink,
                    });
                }
                return images;
            }, [] as Array<{ title: string; src: string; permalink: string }>),
        []
    );

    return images.length > 0 ? (
        <Container className="Gallery">
            <Row>
                {images.map((image, index) => (
                    <Col xs={4} key={index}>
                        <a
                            href={image.permalink}
                            className="d-block w-100 h-100"
                        >
                            <img
                                src={image.src}
                                alt={image.title}
                                title={image.title}
                                className="d-block w-100"
                            />
                        </a>
                    </Col>
                ))}
            </Row>
        </Container>
    ) : (
        <div className="w-100 h-50 d-flex justify-content-center align-items-center">
            {t("gallery.no_image")}
        </div>
    );
};

export default Gallery;
