import React from "react";
import { SocialIcon } from "react-social-icons";

import "./Socials.scss";

const Socials: React.FC = () => {
    return (
        <aside className="Socials">
            <SocialIcon
                url="https://www.instagram.com/editions_cambourakis/"
                target="_blank"
                className="mx-2"
            />
            <SocialIcon
                url="https://www.facebook.com/Edition.Cambourakis"
                target="_blank"
                className="mx-2"
            />
            <SocialIcon
                url="https://twitter.com/ECambourakis"
                target="_blank"
                className="mx-2"
            />
        </aside>
    );
};

export default Socials;
