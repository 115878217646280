import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import EditHandler from "../../common/editHandler/EditHandler";

import UserContext from "../../context/UserContext";
import { getContent, updateContent } from "../../utils/api/api";

type PageContentProps = {
    contentIndex?: number;
};

const PageContent: React.FC<PageContentProps> = (props) => {
    const { contentIndex = -1 } = props;

    const [state, setState] = useState<{
        text: string;
        tempText: string;
    }>({
        text: "",
        tempText: "",
    });

    const [editMode, setEditMode] = useState(false);
    const [previewMode, setPreviewMode] = useState(false);

    const { user } = useContext(UserContext);

    const fetchData = async () => {
        if (contentIndex > -1) {
            const textRes = await getContent(contentIndex);
            if (textRes.data) {
                const text = decodeURIComponent(textRes.data);
                setState((state) => ({
                    ...state,
                    text,
                    tempText: text,
                }));
            }
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (editMode) {
            setState((state) => ({
                ...state,
                tempText: state.text,
            }));
        }
    }, [editMode]);

    const handleSave = async () => {
        const res = await updateContent(
            user.username,
            user.password,
            contentIndex,
            encodeURIComponent(state.tempText)
        );
        if (res.data === true) {
            setState((state) => ({ ...state, text: state.tempText }));
            setEditMode(false);
        }
    };

    return (
        <Container className="pageContent">
            {user.admin && contentIndex > 0 && (
                <Row>
                    <Col></Col>
                    <Col xs="auto">
                        <EditHandler
                            editMode={editMode}
                            setEditMode={setEditMode}
                            onSave={handleSave}
                            previewMode={previewMode}
                            togglePreview={() =>
                                setPreviewMode((previewMode) => !previewMode)
                            }
                        />
                    </Col>
                </Row>
            )}
            <Row>
                {user.admin && contentIndex > 0 && editMode ? (
                    <>
                        <Col>
                            <Form.Control
                                as="textarea"
                                className="h-100"
                                style={{ minHeight: 500 }}
                                value={state.tempText}
                                onChange={(e) =>
                                    setState((state) => ({
                                        ...state,
                                        tempText: e.target.value,
                                    }))
                                }
                            />
                        </Col>
                        {previewMode && (
                            <Col>
                                <p
                                    className="text-justify"
                                    dangerouslySetInnerHTML={{
                                        __html: state.tempText,
                                    }}
                                ></p>
                            </Col>
                        )}
                    </>
                ) : (
                    <Col>
                        <p
                            className="text-justify"
                            dangerouslySetInnerHTML={{
                                __html: state.text,
                            }}
                        ></p>
                    </Col>
                )}
            </Row>
        </Container>
    );
};

export default PageContent;
