import React, { useState } from "react";
import { Carousel, Col, Container, Nav, Row } from "react-bootstrap";
import Author from "../../components/author/Author";

import leslie from "../../__assets__/img/authors/leslie.webp";
import noemie from "../../__assets__/img/authors/noemie.jpg";
import maassi from "../../__assets__/img/authors/maassi.jpg";

import "./Authors.scss";

type AuthorsProps = {
    contentIndexes: [number, number, number];
};

const Authors: React.FC<AuthorsProps> = (props) => {
    const { contentIndexes } = props;

    const [index, setIndex] = useState(0);

    const handleSelect = (eventKey: any) => {
        setIndex(Number(eventKey));
    };

    return (
        <Container className="authors h-100">
            <Row className="h-100">
                <Col>
                    <Nav
                        justify
                        variant="tabs"
                        activeKey={index}
                        onSelect={handleSelect}
                    >
                        <Nav.Item>
                            <Nav.Link eventKey="0">Leslie Feinberg</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="1">Noémie Grunenwald</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="2">Maassi</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Carousel
                        variant="dark"
                        activeIndex={index}
                        onSelect={handleSelect}
                        interval={null}
                        indicators={false}
                    >
                        <Carousel.Item className="h-100">
                            <Author
                                name="Leslie Feinberg"
                                img={leslie}
                                contentIndex={contentIndexes[0]}
                            />
                        </Carousel.Item>
                        <Carousel.Item className="h-100">
                            <Author
                                name="Noémie Grunenwald"
                                img={noemie}
                                contentIndex={contentIndexes[1]}
                            />
                        </Carousel.Item>
                        <Carousel.Item className="h-100">
                            <Author
                                name="Maassi"
                                img={maassi}
                                contentIndex={contentIndexes[2]}
                            />
                        </Carousel.Item>
                    </Carousel>
                </Col>
            </Row>
        </Container>
    );
};

export default Authors;
