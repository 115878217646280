import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Testimonials = () => {
    const { t } = useTranslation();
    return (
        <div className="d-flex justify-content-center mt-5">
            <a href="https://www.google.com">
                <Button variant="primary" size="lg">
                    {t("forum.button")}
                </Button>
            </a>
        </div>
    );
};

export default Testimonials;
