import axios from "axios";

const basePath = "https://stonebutchblues.juliencaillaud.com/api";

type APIEvent = {
    id: string;
    title: string;
    content: string;
    date: string;
    link?: string;
};

const getEvents = () =>
    axios.get<Array<APIEvent>>(basePath + "/events/getEvents.php");

const deleteEvent = (id: string) =>
    axios.get(basePath + "/events/deleteEvent.php?id=" + id);

const createEvent = (
    title: string,
    content: string,
    date: string,
    link?: string
) =>
    axios.post(basePath + "/events/createEvent.php", {
        title,
        content,
        date,
        link,
    });

const getContent = (id: number) =>
    axios.get(basePath + "/contents/getContent.php?id=" + id);

const updateContent = (
    login: string,
    password: string,
    id: number,
    content: string
) =>
    axios.post(basePath + "/contents/updateContent.php", {
        login,
        password,
        id,
        content,
    });

const tryLogin = (login: string, password: string) =>
    axios.post(basePath + "/users/login.php", {
        login,
        password,
    });

export {
    getEvents,
    deleteEvent,
    createEvent,
    getContent,
    updateContent,
    tryLogin,
};

export type { APIEvent };
