import React, { useContext, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserContext from "../../context/UserContext";
import { tryLogin } from "../../utils/api/api";

const Login = () => {
    const [show, setShow] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);

    const { user, setUser } = useContext(UserContext);

    const { t } = useTranslation();
    const t_login = t("login.login");
    const t_password = t("login.password");

    const login = async (username: string, password: string) => {
        const res = await tryLogin(username, password);
        if (res.data) {
            setUser({ username, password, admin: !!res.data.admin });
            return true;
        }

        logout();
        return false;
    };

    const logout = () => {
        setUser({ username: "", password: "", admin: false });
    };

    const handleClose = () => {
        setShow(false);
        setIsInvalid(false);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsInvalid(false);

        const { username, password } = e.target.elements;
        if (await login(username.value, password.value)) {
            handleClose();
        } else {
            setIsInvalid(true);
        }
    };

    return (
        <>
            <Button onClick={() => setShow(true)}>
                {user.username ? user.username : t("login.button")}
            </Button>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t("login.title")}</Modal.Title>
                </Modal.Header>
                {user.username ? (
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t("login.cancel")}
                        </Button>
                        <Button variant="danger" onClick={logout}>
                            {t("login.logout")}
                        </Button>
                    </Modal.Footer>
                ) : (
                    <>
                        <Modal.Body
                            as={Form}
                            id="loginForm"
                            onSubmit={handleSubmit}
                        >
                            <Form.Group className="mb-3" controlId="username">
                                <Form.Label>{t_login}</Form.Label>
                                <Form.Control
                                    placeholder={t_login}
                                    required
                                    isInvalid={isInvalid}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>{t_password}</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder={t_password}
                                    required
                                    isInvalid={isInvalid}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                {t("login.cancel")}
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                form="loginForm"
                            >
                                {t("login.button")}
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Modal>
        </>
    );
};

export default Login;
