import React, { useRef, useState } from "react";
import {
    Button,
    Col,
    Container,
    FloatingLabel,
    Form,
    Row,
    Spinner,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const Contact = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const form = useRef<HTMLFormElement>(null);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            toast.success(t("contact.sent"));
            form.current?.reset();
        }, 2000);
    };
    return (
        <Container>
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit} ref={form}>
                        <Container>
                            <Row className="mb-4">
                                <Col>
                                    <Form.Group controlId="recipient">
                                        <Row>
                                            <Col
                                                xs="auto"
                                                className="d-flex align-items-center"
                                            >
                                                <Form.Label className="mb-0">
                                                    {t("contact.receiver")}
                                                </Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Select required>
                                                    <option>
                                                        {t(
                                                            "contact.cambourakis_ed"
                                                        )}
                                                    </option>
                                                    <option>
                                                        Mélissa Blanchard -{" "}
                                                        {t("contact.relations")}
                                                    </option>
                                                    <option>
                                                        Ju Caillaud -{" "}
                                                        {t("contact.webmaster")}
                                                    </option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <FloatingLabel
                                        controlId="firstName"
                                        label={t("contact.firstname")}
                                    >
                                        <Form.Control
                                            placeholder={
                                                t("contact.firstname") || ""
                                            }
                                            required
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel
                                        controlId="lastName"
                                        label={t("contact.lastname")}
                                    >
                                        <Form.Control
                                            placeholder={
                                                t("contact.lastname") || ""
                                            }
                                            required
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <FloatingLabel
                                        controlId="email"
                                        label="Email"
                                    >
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            required
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <FloatingLabel
                                        controlId="message"
                                        label="Message"
                                    >
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            style={{ height: "auto" }}
                                            placeholder="Message"
                                            required
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button
                                        type="submit"
                                        className="d-block ms-auto"
                                        disabled={loading}
                                    >
                                        {t("contact.send")}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </Col>
                <Col className="d-flex justify-content-center align-items-center">
                    {loading && <Spinner animation="grow" variant="primary" />}
                </Col>
            </Row>
        </Container>
    );
};

export default Contact;
