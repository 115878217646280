import React from "react";
import ReactDOM from "react-dom/client";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import App from "./App";

import en from "./__locales__/en.json";
import fr from "./__locales__/fr.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "./__assets__/fonts/compose-black.ttf";
import "./index.scss";

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en,
            fr,
        },
        fallbackLng: "fr",
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    });

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
