import React, { useState } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PageContent from "../../common/pageContent/PageContent";

import "./Cambourakis.scss";
import Team from "./team/Team";

enum CAMBOURAKIS {
    HOUSE,
    COLLECTION,
    TEAM,
}

const Cambourakis = () => {
    const [page, setPage] = useState(CAMBOURAKIS.HOUSE);

    const { t } = useTranslation();

    return (
        <Container className="cambourakis">
            <Row>
                <Col>
                    <Nav
                        variant="pills"
                        defaultActiveKey={CAMBOURAKIS.HOUSE}
                        onSelect={(eventKey) => {
                            setPage(Number(eventKey));
                        }}
                    >
                        <Nav.Item>
                            <Nav.Link eventKey={CAMBOURAKIS.HOUSE}>
                                {t("cambourakis.publishing_house")}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey={CAMBOURAKIS.COLLECTION}>
                                {t("cambourakis.sorcieres_collection")}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey={CAMBOURAKIS.TEAM}>
                                {t("cambourakis.our_team")}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    {(() => {
                        switch (page) {
                            case CAMBOURAKIS.HOUSE:
                                return (
                                    <PageContent contentIndex={2} key="house" />
                                );
                            case CAMBOURAKIS.COLLECTION:
                                return (
                                    <PageContent
                                        contentIndex={10}
                                        key="collection"
                                    />
                                );
                            case CAMBOURAKIS.TEAM:
                                return <Team />;
                        }
                    })()}
                </Col>
            </Row>
        </Container>
    );
};

export default Cambourakis;
