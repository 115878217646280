import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Flipbook } from "../../components/flipbook/Flipbook";

import couverture from "../../__assets__/img/couverture.png";

const Typescript = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);

    return (
        <>
            <Container className="h-100">
                <Row className="h-75 justify-content-center">
                    <Col xs={{ span: "auto", offset: 2 }} className="h-100">
                        <Button
                            className="h-100"
                            variant=""
                            onClick={() => setShow(true)}
                            title={t("flipbook.read") || ""}
                        >
                            <img
                                src={couverture}
                                alt="Stone Butch Blues"
                                className="h-100"
                            />
                        </Button>
                    </Col>
                    <Col xs={2} className="h-100 d-flex align-items-center">
                        <a
                            href="http://www.makassar-diffusion.com/A-29917-stone-butch-blues.aspx"
                            className="w-100"
                        >
                            <Button variant="warning" className="w-100">
                                {t("flipbook.buy")}
                            </Button>
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        © Leslie Feinberg, 2014 & © Hystériques & AssociéEs,
                        2019.
                    </Col>
                </Row>
            </Container>
            <Flipbook show={show} onHide={() => setShow(false)} />
        </>
    );
};

export default React.memo(Typescript);
