import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

type TranslationSelectProps = {
    languages: string[];
};

const TranslationSelect: React.FC<TranslationSelectProps> = (props) => {
    const { i18n, t } = useTranslation();

    const onChangeLanguage = (lng: string) => {
        if (i18n.getDataByLanguage(lng)) {
            i18n.changeLanguage(lng);
        } else {
            toast.error(t("translation.not_found", { lng: lng.toUpperCase() }));
        }
    };

    return (
        <Form.Select
            onChange={(e) => onChangeLanguage(e.target.value)}
            value={i18n.resolvedLanguage}
        >
            {props.languages.map((lng) => (
                <option key={lng} value={lng}>
                    {lng.toUpperCase()}
                </option>
            ))}
        </Form.Select>
    );
};

export default TranslationSelect;
