import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

import frederic from "../../../__assets__/img/team/frederic_cambourakis.jpg";
import isabelle from "../../../__assets__/img/team/isabelle_cambourakis.jpg";
import laurence from "../../../__assets__/img/team/laurence_bourgeon.jpg";
import charlotte from "../../../__assets__/img/team/charlotte_groult.jpg";
import garance from "../../../__assets__/img/team/garance_ducol.jpg";
import rezza from "../../../__assets__/img/team/rezza_ould_amer.jpeg";

import { useTranslation } from "react-i18next";

const Team = () => {
    const { t } = useTranslation();

    return (
        <Container className="Team">
            <Row>
                <Col xs={2}>
                    <Card>
                        <Card.Img variant="top" src={frederic} />
                        <Card.Body>
                            <Card.Title>Frédéric Cambourakis</Card.Title>
                            <Card.Subtitle>
                                {t("cambourakis.team.director")}
                            </Card.Subtitle>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={2}>
                    <Card>
                        <Card.Img variant="top" src={isabelle} />
                        <Card.Body>
                            <Card.Title>Isabelle Cambourakis</Card.Title>
                            <Card.Subtitle>
                                {t("cambourakis.team.sorcieres_director")}
                            </Card.Subtitle>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={2}>
                    <Card>
                        <Card.Img variant="top" src={laurence} />
                        <Card.Body>
                            <Card.Title>Laurence Bourgeon</Card.Title>
                            <Card.Subtitle>
                                {t("cambourakis.team.publisher_f")}
                            </Card.Subtitle>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={2}>
                    <Card>
                        <Card.Img variant="top" src={charlotte} />
                        <Card.Body>
                            <Card.Title>Charlotte Groult</Card.Title>
                            <Card.Subtitle>
                                {t("cambourakis.team.publisher_f")}
                            </Card.Subtitle>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={2}>
                    <Card>
                        <Card.Img variant="top" src={garance} />
                        <Card.Body>
                            <Card.Title>Garance Ducol</Card.Title>
                            <Card.Subtitle>
                                {t("cambourakis.team.publisher_f")}
                            </Card.Subtitle>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={2}>
                    <Card>
                        <Card.Img variant="top" src={rezza} />
                        <Card.Body>
                            <Card.Title>Rezza Ould Amer</Card.Title>
                            <Card.Subtitle>
                                {t("cambourakis.team.publisher_m")}
                            </Card.Subtitle>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Team;
