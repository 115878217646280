import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import EditHandler from "../../common/editHandler/EditHandler";
import UserContext from "../../context/UserContext";
import { getContent, updateContent } from "../../utils/api/api";

type AuthorProps = {
    name: string;
    img: string;
    contentIndex: number;
};

const Author: React.FC<AuthorProps> = (props) => {
    const { name, img, contentIndex } = props;
    const [state, setState] = useState<{
        text: string;
        tempText: string;
    }>({
        text: "",
        tempText: "",
    });

    const fetchData = async () => {
        if (contentIndex > 0) {
            const textRes = await getContent(contentIndex);
            if (textRes.data) {
                const text = decodeURIComponent(textRes.data);
                setState((state) => ({
                    ...state,
                    text,
                    tempText: text,
                }));
            }
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { user } = useContext(UserContext);
    const [editMode, setEditMode] = useState(false);
    const [previewMode, setPreviewMode] = useState(false);

    useEffect(() => {
        if (editMode) {
            setState((state) => ({
                ...state,
                tempText: state.text,
            }));
        }
    }, [editMode]);

    const handleSave = async () => {
        const res = await updateContent(
            user.username,
            user.password,
            contentIndex,
            encodeURIComponent(state.tempText)
        );
        if (res.data === true) {
            setState((state) => ({ ...state, text: state.tempText }));
            setEditMode(false);
        }
    };

    return (
        <div className="carousel-padding">
            <Container className="h-100">
                {user.admin && contentIndex > 0 && (
                    <Row>
                        <Col></Col>
                        <Col xs="auto">
                            <EditHandler
                                editMode={editMode}
                                setEditMode={setEditMode}
                                onSave={handleSave}
                                previewMode={previewMode}
                                togglePreview={() =>
                                    setPreviewMode(
                                        (previewMode) => !previewMode
                                    )
                                }
                            />
                        </Col>
                    </Row>
                )}
                <Row className="h-100">
                    <Col xs={3}>
                        <div className="image">
                            <img src={img} alt={name} />
                        </div>
                    </Col>
                    <Col>
                        {user.admin && contentIndex > 0 && editMode ? (
                            <>
                                <Form.Control
                                    as="textarea"
                                    style={{
                                        minHeight: previewMode ? "50%" : "90%",
                                    }}
                                    value={state.tempText}
                                    onChange={(e) =>
                                        setState((state) => ({
                                            ...state,
                                            tempText: e.target.value,
                                        }))
                                    }
                                />
                                {previewMode && (
                                    <p
                                        className="text-justify"
                                        dangerouslySetInnerHTML={{
                                            __html: state.tempText,
                                        }}
                                    ></p>
                                )}
                            </>
                        ) : (
                            <Row>
                                <Col>
                                    <p
                                        className="text-justify"
                                        dangerouslySetInnerHTML={{
                                            __html: state.text,
                                        }}
                                    ></p>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Author;
