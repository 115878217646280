import React, { useEffect, useRef } from "react";
import PageContent from "../../common/pageContent/PageContent";

type PageProps = {
    id: string;
    onObserve: (title: string) => void;
    contentIndex?: number;
    title?: string;
    children?: React.ReactNode;
};

const Page: React.FC<PageProps> = (props) => {
    const { id, onObserve, title = "" } = props;

    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) onObserve(title);
            },
            { threshold: 1 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [title, onObserve]);

    return (
        <div id={id} className="page reducedHeight" ref={ref}>
            {props.children ? (
                props.children
            ) : (
                <PageContent contentIndex={props.contentIndex} />
            )}
        </div>
    );
};

export default Page;
